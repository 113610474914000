<template>
    <header>
        <nav class="navbar navbar-expand-lg header-nav d-block d-lg-none">
            <div class="container-fluid header-content">
                    <router-link :to="{name:'home'}" class="navbar-brand">
                        <img class="img-fluid header-logo" src="/assets/images/logo.png" alt="MagnoFx" title="MagnoFx">
                    </router-link>
                <button class="navbar-toggler" id="nav-icon2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav m-0">
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a class="nav-tag dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Trading</a>
                                <div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown" 
                                    >
                                        <li >
                                            <router-link  :to="{name:'Account Zero'}"  class="dropdown-item">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Account types
                                                </span>
                                            
                                            </router-link>
                                        </li>
                                        <li >
                                            <router-link  :to="{name:'Deposit Withdrawal'}"  class="dropdown-item">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Deposit & Withdrawals

                                                </span>

                                            </router-link>
                                        </li>
                                        <li >
                                            <router-link  :to="{name:'Bonus'}"  class="dropdown-item">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Deposit Bonus

                                                </span>
                                            </router-link>
                                        </li>
                                        <li >
                                            <router-link  :to="{name:'Fees'}"  class="dropdown-item">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Fees
                                                </span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a class="nav-tag dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Markets</a>
                                <div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Forex'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Forex
                                                    
                                                </span>    
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Indices'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Indices
                                                    
                                                </span>
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Commodities'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Commodities
                                                    
                                                </span>
                                            </router-link>
                                            </li>
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Crypto'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Cryptocurrencies
                                                    </span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a class="nav-tag dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Platforms</a>
                                <div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Magno Terminal'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    
                                                    MFX4
                                                </span>    
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Magno Terminal5'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    MFX5

                                                </span>
                                            </router-link>
                                        </li>   
                                        <!-- <li>
                                            <router-link class="dropdown-item" :to="{name:'Magno App'}">App</router-link>
                                        </li> -->
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a class="nav-tag dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Tools</a>
                                <div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Calculator'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Calculators

                                                </span>
                                            
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a class="nav-tag dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Company</a>
                                <div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Why Us'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Why MFX

                                                </span>
                                            
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Client Protection'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Client & Security

                                                </span>
                                            </router-link>
                                        </li>
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Contact Us'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">

                                                    Contact Us
                                                </span>
                                            </router-link>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a class="nav-tag dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">Partner</a>
                                <div>
                                    <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li>
                                            <router-link class="dropdown-item" :to="{name:'Partners'}">
                                                <span  data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">
                                                    Partnerships

                                                </span>
                                            </router-link>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </li>
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                <a href="https://cp.magnofx.com/register" target="_blank" class="transparent nav-tag">Register</a>

                            </li>
                            <li class="nav-item dropdown menu-item-has-children parent menu-drop-btn mt-3">
                                 <a href="https://cp.magnofx.com/login" target="_blank" class="nav-tag">Login</a>
                            </li>
                        </ul>
                </div>
        </div>
    
    </nav>
    <div class="header-nav d-none d-lg-block">
            <div class="header-content">
                <div class="nav-icon" id="nav-icon2">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                <div class="logo">
                    <router-link :to="{name:'home'}" class="logo-text">
                        <img src="/assets/images/logo.png" alt="MagnoFx" title="MagnoFx">
                    </router-link>
                </div>
                <div class="menu main-nav">
                    <ul class="m-0">
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Trading</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Account Zero'}">Account types</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Deposit Withdrawal'}">Deposit & Withdrawals</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Bonus'}">Deposit Bonus</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Fees'}">Fees</router-link>
                                    </li>
                                    
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Markets</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Forex'}">Forex</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Indices'}">Indices</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Commodities'}">Commodities</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Crypto'}">Cryptocurrencies</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Platforms</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Magno Terminal'}">MTX4</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Magno Terminal5'}">MTX5</router-link>
                                    </li>   
                                    <!-- <li>
                                        <router-link :to="{name:'Magno App'}">App</router-link>
                                    </li> -->
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Tools</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Calculator'}">Calculators</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Company</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Why Us'}">Why MFX</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Client Protection'}">Client & Security</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'Contact Us'}">Contact Us</router-link>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li class="menu-item-has-children parent menu-drop-btn">
                            <a href="">Partner</a>
                            <div>
                                <ul>
                                    <li>
                                        <router-link :to="{name:'Partners'}">Partnerships</router-link>
                                    </li>
                                    
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <!-- <div class="cta">
                        <a href="" class="transparent">Register</a>
                        <a href="">Login</a>
                    </div> -->
                </div>
                <div class="cta">
                    <a href="https://cp.magnofx.com/register" target="_blank" class="transparent">Register</a>
                    <a href="https://cp.magnofx.com/login" target="_blank">Login</a>
                </div>
            </div>
           
        </div>
        <div class="overlay"></div>
    </header>
</template>
