<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">About Us</h3>
                    <h1>Why choose MagnoFX</h1>
                    <p>With our superior-than-market conditions, you will have the best chance of succeeding in trading.</p>
                    <div class="cta">
                        <a href="https://cp.magnofx.com/register" target="_blank" class="blue-btn mr-3">Register</a>
                        <a href="https://cp.magnofx.com/dashboard" target="_blank" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/why-us/why-us-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <h2>Boost your trading performance</h2>
                <p>Your strategy has the advantage it deserves thanks to our competitive market conditions, trading features, and proprietary protections.</p> 
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-umbrella.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Stop Out Protection</h3>
                            <p>Trade FX with an innovative market protection feature designed to safeguard your positions from short-term market fluctuations, preventing or minimizing stop outs and delays.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Low and stable spreads</h3>
                            <p>Engage in FX trading with consistently low and and transparent fee. Benefit from narrow spreads that remain steady, even amidst economic news releases and market events.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Fast execution</h3>
                            <p>Leverage the frequent price movements of popular currency pairs with lightning-fast execution. Have your FX trading orders executed within milliseconds.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-zero.png" alt="">
                        </div>
                        <div class="text">
                            <h3>0% stop out level</h3>
                            <p>Trade Forex online with a unique market protection feature that shields your positions against temporary market volatility and delays or avoids stop outs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-scale.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Customizable leverage</h3>
                            <p>Choose the one that will maximize your returns while minimizing your costs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-dollar.png" alt="">
                        </div>
                        <div class="text">
                            <h3>No overnight fees</h3>
                            <p>We've eliminated swap fees for most of our instruments, including majors, crypto, and gold.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Columns End -->  

    <!-- Three Steps Start -->
    <section class="three-steps">
        <div class="container">
            <div class="title">
                <h2>Access your funds instantly</h2>
            <p>Without paying any extra fees, you can access your money whenever and however you choose.</p>
            </div>
            <!-- <div class="three-steps__text">
                <div class="row">
                    <div class="col-md-6">
                        <div class="left">
                            <h2>RAW spread and commissions</h2>
                            <p>Commission and spread information for individual instruments</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right">
                            <div>
                                <h3>Currencies</h3>
                                <p>Trade the largest market in the world with spreads commencing at 0 pip.</p>                        
                            </div>
                            <div>
                                <h3>Commodities</h3>
                                <p>Portfolio diversification through trading in oil, natural gas, and metals</p>                        
                            </div>
                            <div>
                                <h3>Indices</h3>
                                <p>Leverage the prominence of tech and other industry titans</p>                        
                            </div>
                            <div>
                                <h3>Cryptocurrencies</h3>
                                <p>Trading around the clock 5 days a week on leading cryptocurrencies such as Bitcoin, Ethereum, and Litecoin</p>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="three-steps__content">
                <div class="image">
                    <!-- <div class="baked-text">
                        <h3>Deposit your funds in 3 easy steps</h3>
                    </div> -->
                    <img src="assets/images/why-us/man-using-phone.jpg" alt="">
                </div>
                <div class="steps">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number icon">
                                    <img src="assets/images/why-us/icon-withdraw.png" alt="">
                                </div>
                                <h4>Instant Deposits and Fast withdrawals: </h4>
                                <p>Receive instant deposits at anytime while withdrawals are processed within an hour, 5 days a week.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number icon">
                                    <img src="assets/images/why-us/icon-zero-small.png" alt="">
                                </div>
                                <h4>ZERO transaction fee</h4>
                                <p>We reimburse any transaction fee incurred from your side back to your trading account.</p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number icon">
                                    <img src="assets/images/why-us/icon-blocks.png" alt="">
                                </div>
                                <h4>Blockchain payments</h4>
                                <p>Send funds instantly and securely around the world to trade with MagnoFx.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Steps End -->  

    <!-- Two Columns Start -->
    <section class="two-columns">
        <div class="container">
            <h2>Seamless, from beginning to end</h2>
            <p>Take advantage of our unparalleled crypto payments ecosystem: effortlessly deposit funds through cryptocurrencies, enjoy round-the-clock access, and experience hassle-free fund withdrawals.</p>
            <div class="row flex-md-row-reverse">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/why-us/img1.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h4>Transparent trading practices</h4>
                        <p>Backtest your trading strategy with our full tick history. See for yourself how reliable our pricing and execution are.</p>
                        <h4>Segregated accounts</h4>
                        <p>Your funds are always kept separate from company funds, so you’ll always have access to them. No ifs, no buts.</p>
                        <h4>Unrivalled customer service</h4>
                        <p>Our team possesses considerable expertise in the forex industry, which enables them to comprehend the desires and requirements of traders.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Two Columns End -->  

    <!-- Two Columns Start -->
    <section class="two-columns pt-5">
    <div class="container">
        <h2>Your trade. We support!</h2>
        <p>You make the decisions about what to trade and when. We're working really hard in the background to reduce your expenses and enhance your performance.</p>
        <div class="row">
            <div class="col-md-6">
                <div class="image">
                    <img src="assets/images/why-us/img2.jpg" alt="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="text">
                    <h4>Reduced trading fee</h4>
                    <p>Take advantage of the market's most affordable commission and spreads. </p>
                    <h4>No to minimum slippage</h4>
                    <p>Deal with big orders using the same spread and terms as other traders.</p>
                    <h4>Improved trade execution</h4>
                    <p>Take advantage of our exclusive trade implementation process, which offers guaranteed liquidity.</p>
                </div>
            </div>
        </div>
    </div>
    </section>
    <!-- Two Columns End --> 
</template>