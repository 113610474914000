<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content pb-0">
                <div class="banner-home__content__text overlapped-2">
                    <h3 class="gradient-title">Platform</h3>
                    <h1>MTX5 (Coming soon)</h1>
                   
                </div>
                <div class="banner-home__content__img overlapped-2">
                    <img src="assets/images/platform-magnoterminal/platform-magnoterminal-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
</template>