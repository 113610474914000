import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";

// Functions first
// import "../node_modules/bootstrap/scss/functions";
import "../node_modules/bootstrap/scss/_functions.scss";

import ToastPlugin from 'vue-toast-notification';
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';

//vee validation rules
import { defineRule, configure } from 'vee-validate'
configure({ validateOnBlur: false, validateOnChange: true, validateOnInput: true, validateOnModelUpdate: false });

defineRule('required', (value, t, ctx) => {
    if (!value || (value + '').trim() == '') {
        return ctx.field + ' field is required.';
    }
    return true;
})



import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)


const app = createApp(App)


app.use(router)
app.use(ToastPlugin);
app.use(pinia)
app.mount('#app')
