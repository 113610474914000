<template>
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content pb-0">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Fees</h3>
                    <h1>MagnaFX Fees</h1>
                    <p>Rather than worrying about payments, concentrate on trading. A trading environment has been created to ensure our clients the most minimal expenses feasible.</p>
                    <div class="cta">
                        <a href="https://cp.magnofx.com/register" target="_blank" class="blue-btn mr-3">Register</a>
                        <a href="https://cp.magnofx.com/dashboard" target="_blank" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="/assets/images/fees/fees-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <!-- <h3 class="gradient-title">Trade with confidence</h3> -->
                <!-- <h2>Why trade indices with MagnoFX</h2>
                <p>Access extensively traded global indices, including the S&P and the US Tech 100, through a broker who understands your requirements.</p> -->
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-no-deposit.png" alt="">
                        </div>
                        <div class="text">
                            <h3>No deposit and withdrawal fee</h3>
                            <p>We cover the fees associated with third-party transactions on your behalf.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-candles.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Accounts matching your requirements</h3>
                            <p>Choose the account type which is most effective to your trading strategy, while minimising your costs.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image icon">
                            <img src="assets/images/icon-graph.png" alt="">
                        </div>
                        <div class="text">
                            <h3>Exclusive leverage of up to 500:1</h3>
                            <p>Control a larger position size with a relatively smaller amount of capital</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Columns End -->  

    <!-- Three Steps Start -->
    <section class="three-steps">
        <div class="container">
            <div class="three-steps__text">
                <div class="row">
                    <div class="col-md-6">
                        <div class="left">
                            <h2>RAW spread and commissions</h2>
                            <p>Commission and spread information for individual instruments</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right">
                            <div>
                                <h3>Currencies</h3>
                                <p>Trade the largest market in the world with spreads commencing at 0 pip.</p>                        
                            </div>
                            <div>
                                <h3>Commodities</h3>
                                <p>Portfolio diversification through trading in oil, natural gas, and metals</p>                        
                            </div>
                            <div>
                                <h3>Cryptocurrencies</h3>
                                <p>Leverage the prominence of tech and other industry titans</p>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="three-steps__content">
                <div class="image">
                    <!-- <div class="baked-text">
                        <h3>Deposit your funds in 3 easy steps</h3>
                    </div> -->
                    <img src="assets/images/fees/man-using-laptop.jpg" alt="">
                </div>
                <!-- <div class="steps">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number">1</div>
                                <h4>Register an account</h4>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number">2</div>
                                <h4>Choose a crypto coin to deposit</h4>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number">3</div>
                                <h4>Initiate an instant deposit to your trading account</h4>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </section>
    <!-- Three Steps End -->  

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" :class="faqTab ==1?'active':''" v-on:click="faqTab = 1">
                        What are the advantage of trading stock index derivatives vs. investing in indices
                    </div>
                    <div v-show="faqTab == 1">
                        <div class="answer">
                            <p>Trading indices derivatives is a great way to gain exposure to the stock indices market without needing to own the underlying asset.</p>

                            <p> Because you're speculating on the performance of an index rather than investing in it, you can capitalize on the movements of prices, whether they're going up or down.</p>

                            <p>You can also use leverage to access the global indices market with a fraction of the capital you would need if you were to invest in indices directly.</p>

                            <p>Not only does this open up the world of major indices to so many more traders, but it also provides unique trading opportunities over multiple time frames, especially when combined with solid index chart technical analysis.</p>

                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 2?'active':''" v-on:click="faqTab = 2">
                        What is leverage in forex trading? 
                    </div>
                    <div v-show="faqTab == 2">
                        <div class="answer">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam asperiores sequi ab itaque harum officia eligendi totam vel incidunt non aliquam ipsam quidem vero, nulla, dolor exercitationem provident repellendus nisi.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 3?'active':''" v-on:click="faqTab = 3">
                        What is margin in online forex trading? 
                    </div>
                    <div v-show="faqTab == 3">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias. 
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 4?'active':''" v-on:click="faqTab = 4">
                        Does my account equity affect the maximum leverage I can use? 
                    </div>
                    <div v-show="faqTab == 4">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 5?'active':''" v-on:click="faqTab = 5">
                        Why are there higher margin requirements around news?
                    </div>
                    <div v-show="faqTab == 5">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 6?'active':''" v-on:click="faqTab = 6">
                        Do margin requirements change around weekends and holidays? 
                    </div>
                    <div v-show="faqTab == 6">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" :class="faqTab == 7?'active':''" v-on:click="faqTab = 7">
                        When does the weekend period of increased margin requirements start and finish?  
                    </div>
                    <div v-show="faqTab == 7">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accord --->
</template>
<script>
    export default {
        data(){
            return{
                faqTab:1
            }
        },
        methods:{},
        created(){}
    }
</script>