<template>
    <div class="loader-wrapper" v-if="loader">
        <div class="loader">
            <img src="/assets/images/magnofx.gif" alt="MagnoFx" title="MagnoFx">
        </div>
    </div>
    
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content with-bottom-line">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Amplify Trading Experience</h3>
                    <h1>Transform your trading experience with MagnoFx</h1>
                    <p>Trade with a leading FX broker and enjoy superior trading conditions surpassing the market standard.</p>
                    <div class="cta">
                        <a href="https://cp.magnofx.com/register" target="_blank" class="blue-btn mr-3">Register</a>
                        <a href="https://cp.magnofx.com/dashboard" target="_blank" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img overlapped">
                    <img src="/assets/images/banner1.png" alt="">
                </div>
            </div>
            <div class="banner-home__bottom">
                <div class="container">
                    <div class="row">
                        <div class="banner-home__bottom__content col-sm-6 col-md-3">
                            <img src="/assets/images/banner-icon1.png" alt="">
                            <div>
                                <span>Min. Deposit  </span>
                                <span>of $10</span>
                            </div>
                        </div>
                        <div class="banner-home__bottom__content col-sm-6 col-md-3">
                            <img src="/assets/images/banner-icon2.png" alt="">
                            <div>
                                <span>Spread from </span>
                                <span>0.0 pips</span>
                            </div>
                        </div>
                        <div class="banner-home__bottom__content col-sm-6 col-md-3">
                            <img src="/assets/images/banner-icon3.png" alt="">
                            <div>
                                <span>Commission from</span>
                                <span>$2 per Lot</span>
                            </div>
                        </div>
                        <div class="banner-home__bottom__content col-sm-6 col-md-3">
                            <img src="/assets/images/banner-icon4.png" alt="">
                            <div>
                                <span>Trade f.rom as low</span>
                                <span>as 0.01 lots</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->

    <!-- Trading Slider Start -->
    <section class="trading-slider">
        <div class="container">
            <h2>Engage in trading without compromising.</h2>
            <div class="row">
                <div class="trading-slider__details col-md-4 order-2 order-md-1" >
                    <div>
                        <h3 class="gradient-title">Withdrawals</h3>
                        <h2>Fast withdrawals</h2>
                        <p>Deposit instantly and get your withdrawal approved in under 60 minutes.</p>
                    </div>
                    
                    <div>
                        <h3 class="gradient-title">Stop outs</h3>
                        <h2>0% stop out level</h2>
                        <p>Trade without limits with our exclusive stop-out feature</p>
                    </div>
                </div>
                <div class="col-md-4 order-1 order-md-2">
                    <div class="trading-slider__content">
                        <div class="trading-slider__content__box">

                        <carousel>
                            <slide v-for="slide in slidesToShow" :key="slide">
                                <div>
                                    <img class="img-fluid" :src="'/assets/images/home/slide'+slide+'.jpg'" alt="MagnoFx" title="MagnoFx">
                                </div>
                            </slide>

                            <template #addons>
                                <pagination />
                            </template>
                        </carousel>
                        </div>
                    </div>
                </div>
                <div class="trading-slider__details col-md-4 order-3 order-md-3">
                    <div>
                        <h3 class="gradient-title">Execution speed</h3>
                        <h2>Ultra-fast execution</h2>
                        <p>Lightening fast execution, regardless of the trade size.</p>
                    </div>
                    
                    <div>
                        <h3 class="gradient-title">Leverage</h3>
                        <h2>Leverage up to 500:1</h2>
                        <p>Control a larger position size with a relatively smaller amount of capital.</p>
                    </div>
                </div>
            </div>
        </div>

    </section>
    <!-- Trading Slider End -->

    <!-- Trading Instruments Start -->
    <section class="trading-instrument">
        <div class="container">
            <div class="text-center">
                <h3 class="gradient-title">Trading Instruments</h3>
                <h2>Our diverse and proprietary liquidity mix keeps spreads tight 24/5</h2>
                <p>Offering favourable trading conditions with real, deep and diverse liquidity</p>
            </div>
            <div>
                <div class="trading-options">
                    <button onclick="dropdownMethod()" class="dropbtn">Select Mehod</button>
                    <div class="trading-options-list" id="trading-options-list">
                        <a href="" class="active"> All</a>
                        <a href="">Forex</a>
                        <a href="">Indices </a>
                        <a href="">Commodities </a>
                        <a href="">Crypto</a>
                    </div>
                </div>
                <div class="trading-instrument__content" >
                    <div class="trading-instruments-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Instruments</th>
                                    <th>Leverage</th>
                                    <th>Ave. spread, pips </th>
                                    <th>Swap Free </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div class="currency">
                                            <img src="/assets/images/home/currency1.png" alt="">
                                            <span>EURUSDm</span>
                                        </div>
                                    </td>
                                    <td>Customizable</td>
                                    <td>1.4</td>
                                    <td>Available</td>
                                    <td>
                                        <img src="/assets/images/home/graph.png" alt="">
                                    </td>
                                    <td>
                                        <a href="" class="forex">Forex</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="currency">
                                            <img src="/assets/images/home/currency2.jpg" alt="">
                                            <span>US30</span>
                                        </div>
                                    </td>
                                    <td>1:400</td>
                                    <td>4.5</td>
                                    <td>Available</td>
                                    <td>
                                        <img src="/assets/images/home/graph.png" alt="">
                                    </td>
                                    <td>
                                        <a href="">Indices</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="currency">
                                            <img src="/assets/images/home/currency3.jpg" alt="">
                                            <span>XAUUSD</span>
                                        </div>
                                    </td>
                                    <td>Customizable</td>
                                    <td>12.5</td>
                                    <td>Available</td>
                                    <td>
                                        <img src="/assets/images/home/graph.png" alt="">
                                    </td>
                                    <td>
                                        <a href="">Metals</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="currency">
                                            <img src="/assets/images/home/currency4.jpg" alt="">
                                            <span>BTCUSDm</span>
                                        </div>
                                    </td>
                                    <td>Customizable</td>
                                    <td>276.4</td>
                                    <td>Available</td>
                                    <td>
                                        <img src="/assets/images/home/graph.png" alt="">
                                    </td>
                                    <td>
                                        <a href="">Crypto</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="text-center cta">
                        <a href="https://cp.magnofx.com/register" target="_blank" class="blue-btn">Register</a>
                        <a href="https://cp.magnofx.com/dashboard" target="_blank" class="grey-btn">Try free demo</a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Trading Instruments End -->

    <!-- Two Columns Start -->
    <section class="two-columns dark">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="image">
                        <img src="/assets/images/home/man.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h3 class="gradient-title">Prioritizing Your Security</h3>
                        <h2>Client funds security is our primary concern</h2>
                        <p>Your protection extends from secure payments to coverage against negative balances.</p>
                        <div>
                            <RouterLink to="/client-protection" class="arrow-right">Client Protection</RouterLink>
                        </div>
                        <div>
                            <RouterLink to="/why_us" class="arrow-right">Why Magno Fx? </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Two Columns End -->  

    <!-- two-columns-overlapped Start -->
    <section class="two-columns-overlapped">
        <div class="container">
            <div class="row flex-md-row-reverse justify-content-end">
                <div class="col-md-6 d-flex">
                    <div class="image">
                        <img src="/assets/images/home/devices.png" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h3 class="gradient-title">Trade Platforms</h3>
                        <h2>Take advantage of every opportunity</h2>
                        <p>Engage in online trading at any time and from any place. Available on web, mobile, and desktop platforms.</p>
                        <div>
                            <a href="https://magnofxlivemt4.tgsm.io/" target="_blank" class="arrow-right">Web Trading Platform</a>
                        </div>
                        <div>
                            <a href="https://magnofxlivemt4.tgsm.io/" target="_blank" class="arrow-right">Phone App</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- two-columns-overlapped End -->  

    <!-- Three Columns Start -->
    <section class="three-columns">
        <div class="container">
            <div class="text-center">
                <h3 class="gradient-title">Trade with confidence</h3>
                <h2>Explore more products</h2>
                <p>Have the best chance at trading success with with Magno Fx</p>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image">
                            <img src="/assets/images/home/product1.jpg" alt="">
                        </div>
                        <div class="text">
                            <h3>Deposit</h3>
                            <p>Discover the convenience and security of Magno Fx deposit processes. Benefit from instant, and secure crypto payment options, ensuring seamless transactions.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image">
                            <img src="/assets/images/home/product2.jpg" alt="">
                        </div>
                        <div class="text">
                            <h3> Deposit Bonus</h3>
                            <p>A deposit bonus adds extra funds to a trader's account, providing them with more capital to trade with. This can potentially amplify profits if used effectively.</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="three-columns__content">
                        <div class="image">
                            <img src="/assets/images/home/product3.jpg" alt="">
                        </div>
                        <div class="text">
                            <h3> Partnerships </h3>
                            <p>Becoming an affiliate with Magno Fx allows individuals to diversify their income streams by regularly referring their clientele in return for revenue share.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Three Columns End -->  

</template>
<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
export default {
    data(){
        return{
            loader:false,
            slidesToShow:3
        }
    },
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation,
    },
    methods: {}
}
</script>