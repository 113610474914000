<template>
     <!--- Banner Start --->
     <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Deposit</h3>
                    <h1>Deposits and withdrawals</h1>
                    <p>Magno Fx offers a streamlined and safe method for both deposits and withdrawals. Benefit from secure, global, and convenient payment options, as well as streamlined transactions.</p>
                    <div class="cta">
                        <a href="https://cp.magnofx.com/register" target="_blank" class="blue-btn mr-3">Register</a>
                        <a href="https://cp.magnofx.com/dashboard" target="_blank" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/deposit-and-withdraw/deposit-withdrawals-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->


    <!-- Two Columns Start -->
    <section class="two-columns">
        <div class="container">
            <h2>Seamless, from beginning to end</h2>
            <p>Take advantage of our unparalleled crypto payments ecosystem: effortlessly deposit funds through cryptocurrencies, enjoy round-the-clock access, and experience hassle-free fund withdrawals.</p>
            <div class="row">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/deposit-and-withdraw/girl-working.jpg" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text">
                        <h4>Efficient methods to transfer your funds</h4>
                        <p>Methods of payment that are secure, convenient, and global to facilitate deposits and withdrawals.</p>
                        <h4>Fast withdrawals around the clock</h4>
                        <p>Obtain your funds fast, at any time of day, through our instant withdrawal feature.</p>
                        <h4>No deposit or withdrawal fee</h4>
                        <p>Magno Fx does not charge any fee. We cover the fees associated with third-party transactions on your behalf.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Two Columns End -->  

    <!-- Three Steps Start -->
    <section class="three-steps">
        <div class="container">
            <div class="three-steps__text">
                <div class="row">
                    <div class="col-md-6">
                        <div class="left">
                            <h2>Client fund safety is priority at Magno Fx</h2>
                            <p>We employ multiple layers of security as a retail multi-asset broker globally to ensure the safety of your funds and provide instant access to them upon your request.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right">
                            <div>
                                <h3>Segregated funds: </h3>
                                <p>We maintain funds in multiple reputed crypto exchanges in segregated accounts for maximum security and peace of mind.</p>                        
                            </div>
                            <div>
                                <h3>Fast and secure withdrawals</h3>
                                <p>Your withdrawals are safe and secure, protected by one-time password verification methods.</p>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="three-steps__content">
                <div class="image">
                    <div class="baked-text">
                        <h3>Deposit your funds in 3 easy steps</h3>
                    </div>
                    <img src="assets/images/deposit-and-withdraw/man-using-phone.jpg" alt="">
                </div>
                <div class="steps">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number">1</div>
                                <h4>Register an account</h4>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number">2</div>
                                <h4>Choose a crypto coin to deposit</h4>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="text">
                                <div class="number">3</div>
                                <h4>Initiate an instant deposit to your trading account</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Three Steps End -->  

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question " v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What methods are available for Deposits and withdrawals? 
                    </div>
                    <div  v-show="activeFaqs==1">
                        <div class="answer">
                            <p>Magno Fx offers the following Cryptocurrencies for deposits and withdrawals: </p>
                            <ul>
                                <li>Bitcoin</li>
                                <li>Ethereum</li>
                                <li>Litecoin</li>
                                <li>USDT (ERC20)</li>
                                <li>USDT (TRC20)</li>
                                <li>USDC</li>
                                <li>Dogecoin</li>
                            </ul>

                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What are Instant deposits and Withdrawals?
                    </div>
                    <div  v-show="activeFaqs==2">
                        <div class="answer">
                            <p>The term "instant" denotes that a transaction will be executed within a few seconds without the need for manual processing by our financial department specialists. However, please be aware that while the transaction is instantly processed on our end, your deposit or withdrawal requests may require additional time to be processed by the payment system provider.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        How long do Cryptocurrency withdrawals take? 
                    </div>
                    <div v-show="activeFaqs==3">
                        <div class="answer">
                            <p>Magno Fx processes the withdrawals instantly from client trading accounts and sends it for processing with one our partner exchange, which can take anything up to 24 hours to receive.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 4" :class="activeFaqs==4?'active':''">
                        Can I withdraw using a cryptocurrency not used to deposit initially with?
                    </div>
                    <div v-show="activeFaqs==4">
                        <div class="answer">
                            <p>The coin utilized for depositing funds holds significance for withdrawals, as typically the same method must be employed for both transactions.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 5" :class="activeFaqs==5?'active':''">
                        When is it possible to deposit and withdraw?
                    </div>
                    <div v-show="activeFaqs==5">
                        <div class="answer">
                            <p>Deposits and withdrawals are available 24/7. If a deposit or withdrawal isn't instant, we'll handle it within 24 hours. However, please note that a congested blockchain  may require additional time for processing. We're not responsible for any delays caused by blockchain traffic. We retain the right to adjust the processing time for deposits and withdrawals without prior notice.</p>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    import { useMainStore } from "@/store/index.ts";
    export default {
        setup(){
            return { store: useMainStore() };
        },
        data(){
            return {
                activeFaqs:'1',
            }
        }
    }
</script>