<template>
    <!--- Banner Start --->
    <section class="banner-home">
        <div class="container">
            <div class="banner-home__content">
                <div class="banner-home__content__text">
                    <h3 class="gradient-title">Security</h3>
                    <h1>Our top priority is client fund safety</h1>
                    <p>Enhanced account security and fund protection are elements of the trading environment that we are devoted to ensuring is secure.</p>
                    <div class="cta">
                        <a href="https://cp.magnofx.com/register" target="_blank" class="blue-btn mr-3">Register</a>
                        <a href="https://cp.magnofx.com/dashboard" target="_blank" class="grey-btn">Try free demo</a>
                    </div>
                </div>
                <div class="banner-home__content__img">
                    <img src="assets/images/client-protection/client-protection-banner.png" alt="">
                </div>
            </div>
        </div>
    </section>
    <!--- Banner End --->


    <!-- Two Columns Start -->
    <section class="two-columns">
        <div class="container">
            <h2>We have go your back</h2>
            <p>As an industry-leading global broker, we provide a variety of <br>account security options.</p>
            <div class="row">
                <div class="col-md-6">
                    <div class="image">
                        <img src="assets/images/client-protection/client-protection-img1.png" alt="">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text list">
                        <ul>
                            <li>
                                <h4>Trust and transparency</h4>
                                <p>Trust and Transparency form the basis of Magno Fx core values. Magno Fx is a reliable Forex CFD provider that applies RAW Spreads on its main and most famous ECN account type, so there is no re-quotes, no price manipulation and no restrictions.</p>
                                <p></p>
                            </li>
                            <li>
                                <h4>Account security </h4>
                                <p>To mitigate the risk of unauthorised access to your secure trading accounts, select a security method during the registration process, such as phone or email.</p>
                                <p></p>
                            </li>
                        </ul>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  <!-- Two Columns End -->  

    <!-- Three Steps Start -->
    <section class="three-steps">
        <div class="container">
            <div class="three-steps__text">
                <div class="row">
                    <div class="col-md-6">
                        <div class="left">
                            <h2>Platform protection</h2>
                            <p>Gain further insights into our robust protection measures and secure trading environment, which guarantee a seamless trading experience.</p>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right">
                            <div>
                                <h3>Protection against web attacks</h3>
                                <p>Our Web Application Firewall (WAF) blocks malicious traffic and safeguards our infrastructure and servers against web threats such as SQL injection and XSS attacks.</p>                        
                            </div>
                            <div>
                                <h3>Tolerance for trading platform faults</h3>
                                <p>Our DDoS protection feature uninterrupted server operations, seamless order execution, and access to your Personal Area around-the-clock. Additionally, it ensures quick deposits and withdrawals.</p>                        
                            </div>
                            <div>
                                <h3>Zero-trust methodology</h3>
                                <p>Including features such as user and device authentication, restricted access, and network monitoring, our Zero Trust model presupposes minimal trust for enterprise IT components.</p>                        
                            </div>
                            <div>
                                <h3>Bug Bounty Scheme</h3>
                                <p>Our Bug Bounty programme provides an additional level of security by soliciting evaluations and feedback from external experts regarding our platforms. These insights assist us in enhancing our services.</p>                        
                            </div>
                            <div>
                                <h3>Competences and knowledge in cybersecurity</h3>
                                <p>Our Information Security Team augments their knowledge of security technologies and expertise through participation in workshops and attainment of certifications.</p>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="three-steps__text">
                <div class="row flex-row-reverse">
                    <div class="col-md-6">
                        <div class="left">
                            <h2>Client fund protection</h2>
                            <p>Explore the numerous premium payment security features that we offer to safeguard your financial transactions.</p>
                            <div class="image">
                                <img src="assets/images/client-protection/client-protection-img2.png" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="right">
                            <div>
                                <h3>Fast withdrawals</h3>
                                <p>We guarantee immediate processing of your withdrawal requests through our automatic withdrawal feature, granting you access to your funds five days a week.</p>                        
                            </div>
                            <div>
                                <h3>Segregated accounts</h3>
                                <p>Your funds are protected by being held in a different location from our own. We are continuously bolstering our funds in order to accommodate your withdrawal requests at any hour of the day.</p>                        
                            </div>
                            <div>
                                <h3>Secure transactions</h3>
                                <p>We guarantee the safety of your transactions. To protect against fraud, user are required to setup a mandatory 2 factor authentication.</p>                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
    <!-- Three Steps End -->  

    <!--- Full Accordion Start --->
    <section class="full-accordion">
        <div class="container">
            <h2>Frequently asked questions </h2>
            <div class="list">
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 1" :class="activeFaqs==1?'active':''">
                        What are the advantage of trading stock index derivatives vs. investing in indices
                    </div>
                    <div v-show="activeFaqs == 1">
                        <div class="answer">
                            <p>Trading indices derivatives is a great way to gain exposure to the stock indices market without needing to own the underlying asset.</p>

                            <p> Because you're speculating on the performance of an index rather than investing in it, you can capitalize on the movements of prices, whether they're going up or down.</p>

                            <p>You can also use leverage to access the global indices market with a fraction of the capital you would need if you were to invest in indices directly.</p>

                            <p>Not only does this open up the world of major indices to so many more traders, but it also provides unique trading opportunities over multiple time frames, especially when combined with solid index chart technical analysis.</p>

                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 2" :class="activeFaqs==2?'active':''">
                        What is leverage in forex trading? 
                    </div>
                    <div v-show="activeFaqs == 2">
                        <div class="answer">
                            <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Aperiam asperiores sequi ab itaque harum officia eligendi totam vel incidunt non aliquam ipsam quidem vero, nulla, dolor exercitationem provident repellendus nisi.</p>
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 3" :class="activeFaqs==3?'active':''">
                        What is margin in online forex trading? 
                    </div>
                    <div v-show="activeFaqs == 3">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias. 
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 4" :class="activeFaqs==4?'active':''">
                        Does my account equity affect the maximum leverage I can use? 
                    </div>
                    <div v-show="activeFaqs == 4">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 5" :class="activeFaqs==5?'active':''">
                        Why are there higher margin requirements around news?
                    </div>
                    <div v-show="activeFaqs == 5">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 6" :class="activeFaqs==6?'active':''">
                        Do margin requirements change around weekends and holidays? 
                    </div>
                    <div v-show="activeFaqs == 6">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
                <div class="box">
                    <div class="question" v-on:click="activeFaqs = 7" :class="activeFaqs==7?'active':''">
                        When does the weekend period of increased margin requirements start and finish?  
                    </div>
                    <div v-show="activeFaqs == 7">
                        <div class="answer">
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos, doloremque veniam quam pariatur fugiat odit consequuntur perspiciatis obcaecati ut itaque velit quo dolor odio saepe cupiditate, recusandae harum at alias.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!--- Full Accordion Accordion End --->
</template>
<script>
    import { useMainStore } from "@/store/index.ts";
    export default {
        setup(){
            return { store: useMainStore() };
        },
        data(){
            return {
                activeFaqs:'1',
            }
        }
    }
</script>